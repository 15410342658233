<template>
  <div class="login">
<div class="login-container" :class="classLogin" :style="styleLogin">
<v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit">


  <v-alert type="success" dismissible v-if="message">
  <span v-html="message"></span>
</v-alert>
<v-card
  class="mx-auto"
  max-width="344"
  outlined
  raised
  v-show="!sending"
  >
  <v-card-title primary-title>
    <div>
      <img class="logo" src="@public/img/logo_login.png" />
    </div>
  </v-card-title>
  <v-card-text>

        <h3>Password dimenticata?</h3>
    <p><strong>La tua password attuale non verrà resettata.</strong></p>
    <p>Riceverai un’email con un <strong>codice temporaneo</strong> che ti permetterà di accedere al tuo account.</p>
    <p>Se desideri modificare la password lo potrai fare dal tuo profilo utente.</p>

    <v-text-field
    v-model="email"
    :rules="emailRules"
    label="E-mail"
    autocomplete
    required
    @input="error=null"
    ></v-text-field>
  </v-card-text>

  <v-card-actions>
  <v-spacer></v-spacer>
  <v-btn text @click="goBack">Annulla</v-btn>

<v-btn
    :disabled="!valid"
    color="primary"
    @click="submit"
    >
    Invia
  </v-btn>
</v-card-actions>

</v-card>
<v-card
  class="mx-auto"
  max-width="344"
  outlined
  raised
  v-show="sending"
  >
  <v-card-title primary-title>
    <div>
      <img class="logo" src="@public/img/logo_login.png" />
    </div>
  </v-card-title>
  <v-card-text>

        <h3>Generazio OTP in corso</h3>
    <p>Stiamo generando il codice OTP da inviarti.</p>
    <p>Pazienta qualche secondo...</p>

  </v-card-text>


</v-card>

<v-alert type="error" v-if="error">
  <span v-html="error"></span>
</v-alert>
</v-form>
</div>

</div>

</template>

<script type="module">
import axios from 'axios'
import env from "@mixins/environment.js"
export default {
  mixins: [
    env,
  ],
  data: () => ({
    classLogin: 'mv-1',
    styleLogin: 'background-position:'+Math.floor(Math.random()*100)+'% 100%',
    valid: false,
    name: '',
    password: '',
    rules: {
      required: value => !!value || 'Richiesto.',
      min: v => (v && v.length >= 5) || 'Min 5 caratteri',
      emailMatch: () => ('The email and password you entered don\'t match')
    },
    email: '',
    error: false,
    message: false,
    emailRules: [
      v => !!v || 'E-mail obbligatoria',
      v => /.+@.+/.test(v) || 'L\'e-mail deve essere valida'
    ],
    select: null,
    show1: false,
    sending: false,

  }),
  mounted: function () {
    this.$refs.form.validate();
    
},

    methods: {
        goBack: function () {
          this.$router.go(-1);
        },
        submit: function () {
        if (!this.$refs.form.validate()) {
            return;
        }

            let data = {
                "email": this.email,
                "type": 'accesso',
            }
            const url = this.api_base_url+'/api/v2/utente.php?otp=1';
            this.sending = true;
            axios({
                method: 'POST',
                url: url,
                data: data,
            })
            .then(
                result=>{
                    if ((result.status === 201)&&(result.data.success)) {
                        this.error = false;
                        this.$router.push({path:"/otp/login",query:{email:this.email}});
                    } else {
                        this.error = result.data.message.text;
                    }
                }
            )
            .catch(
                (result)=>{
                    this.error = result.message;
                }
            )
         },
    }
}
</script>

<style lang="scss"></style>
