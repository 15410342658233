<template>
  <div class="login">
    <v-dialog
    v-model="dialog"
    persistent
    max-width="290"
    class="dialog-install"
    >
    <v-card>
      <v-card-title class="headline">
        Istruzioni
      </v-card-title>
      <v-card-text>

        <ol>
          <li><strong>Aggiungi questa pagina alla Home del tuo dispositivo:</strong>
            <dl>
              <dt><img src="@assets/safari.png" alt="" class="icona-browser">Safari</dt>
              <ol>
                <li>Sulla barra degli strumenti seleziona "Condividi" <img class="install-icon" src="@assets/condividi.png" /></li>
                <li>Dall'elenco seleziona "Aggiungi a Home" <img class="install-icon" src="@assets/aggiungi-home.png" /></li>
              </ol>
              <dt><img src="@assets/chrome1.png" alt="" class="icona-browser">Chrome</dt>
              <dd>
                <ol>
                  <li>Seleziona Opzioni<img src="@assets/opzioni.png" alt="" class="install-icon"></li>
                  <li>Seleziona "Aggiungi a schermata Home"</li>
                </ol>
              </dd>
            </dl>
          </li>
          <li><strong>Apri la webAPP dall’icona appena creata sulla Home del tuo dispositivo e comincia ad allenarti.</strong></li>
        </ol>

      </v-card-text>
      <v-card-actions>
        <v-btn
        x-small
        color="gray"
        text
        @click="prompt = false; dialog = false"
        >
        Ignora
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
      color="green darken-1"
      text
      @click="dialog = false"
      >
      Chiudi
    </v-btn>
  </v-card-actions>
</v-card>
</v-dialog>
<div class="login-container" :class="classLogin" :style="styleLogin">
  <div class="update">

    <v-btn
    icon
    @click="update"
    >

    <v-icon>mdi-sync</v-icon>
  </v-btn>
  <p class="version">{{version}}{{flag_env}}</p>
</div>
<v-alert
v-show="prompt"
max-width="344"

color="#fff"
dark
class="install"
>
    <img class="logo" src="@public/img/logo_login.png" />

<v-btn
@click="install"
color="secondary"
>
Istruzioni
</v-btn>
</v-alert>
<v-form v-show="!prompt" ref="form" v-model="valid" lazy-validation>


  <v-alert type="success" dismissible v-if="message">
  {{message}}
</v-alert>
<v-card
        class="mx-auto"
        max-width="344"
        outlined
        raised
        >
        <v-card-title primary-title>
            <div>
                <img class="logo" src="@public/img/logo_login.png" />
            </div>
        </v-card-title>
        <v-card-text>

            <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="E-mail"
                    autocomplete="username"
                    type="email"

                    required
                    ></v-text-field>

            <v-text-field
                    v-model="password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Password"
                    counter
                    autocomplete="current-password"
                    @click:append="show1 = !show1"
                    ></v-text-field>
        </v-card-text>

        <v-card-actions>

            <v-btn
                    :disabled="!valid"
                    block
                    class="primary"
                    @click="submit"
                    >
                    Entra
            </v-btn>
        </v-card-actions>
        <v-card-text>
            Devi attivare il tuo account o hai dimenticato la password?
        </v-card-text>
        <v-card-actions>
            <v-btn block class='secondary' @click.prevent="requestOtp">Accedi con OTP</v-btn>
        </v-card-actions>
        <v-card-actions>
            <v-spacer></v-spacer>
            <p class="text-small">
            Non hai un account? <v-btn x-small @click="register">Registrati</v-btn>
            </p>
        </v-card-actions>
</v-card>


<v-alert type="error" v-if="error">
  {{error}}
  <template v-if="utenteNonAttivo">
  <v-btn color='primary' @click="confirmEmailWithOtp">Attiva</v-btn>
  </template>
</v-alert>
</v-form>
</div>

</div>

</template>

<script type="module">
import axios from 'axios'
import mapRoutes from "@mobilesrc/router/mapRoutes.js"
import env from "@mixins/environment.js"
import prompt from "@mixins/promptPWA.js"
import version from "@mixins/version.js"
import { mapState } from 'vuex'
export default {
  mixins: [
    env,
    prompt,
    version,
  ],
  data: () => ({
      page: {
          path: null,
          query: null,
      },
      pageDefault: '/calendario',
    dialog: false,
    prompt: false,
    classLogin: 'mv-1',
    styleLogin: 'background-position:'+Math.floor(Math.random()*100)+'% 100%',
    valid: true,
    name: '',
    password: '',
    rules: {
      required: value => !!value || 'Richiesto.',
      min: v => (v && v.length >= 5) || 'Min 5 caratteri',
      emailMatch: () => ('The email and password you entered don\'t match')
    },
    email: '',
    error: false,
    message: false,
    emailRules: [
      v => !!v || 'E-mail obbligatoria',
      v => /.+@.+/.test(v) || 'L\'e-mail deve essere valida'
    ],
    select: null,
    show1: false,
  }),
  mounted: async function () {
      this.checkRouteQuery();
      this.checkHash();

      prompt.init();
      //this.prompt = prompt.needsToSeePrompt();
      const query = this.$route.query;
      const logout = query.logout;
      if ('email' in query) {
          this.email = query.email;
      }
      if ('password' in query) {
          this.password = query.password;
      }

      this.classLogin = (Math.random() > 0.5) ? "mv-0" : "mv-1";
      if (logout) {
          this.$store.dispatch("utente/logout");
          this.$router.replace({'name':'home'})
          .then(()=>{
              window.location.reload(true)

          });


      }
      const loggedIn = await this.$store.dispatch("utente/login");
      if (loggedIn) {
          this.error = false;
          this.$router.replace("/calendario");
      } else {
          this.error = false;
      }
  },

computed: {
    ...mapState( {
        utente: (state)=>state.utente.utente,
        auth_token: (state)=>state.utente.auth_token,
      }
    ),
    utenteNonAttivo: function () {
        if (!(this.error && this.error.length)) return false;
        return this.error.toLowerCase() === "utente non attivo";
    }
},

methods: {
        sendOtp: function (email, type) {
            let data = {
                "email": email,
                "type": type,
            }
            return new Promise((resolve)=>{
                const url = this.api_base_url+'/api/v2/utente.php?otp';
                axios({
                    method: 'POST',
                    url: url,
                    data: data
                })
                .then(
                (response) => {
                    let result = response.data;
                    resolve(result);
                },
                ()=>{
                    resolve({success:false});
                }
                );
            });
        },

        confirmEmailWithOtp: async function () {
            const email = this.email;
            const type = 'conferma';
            const result = await this.sendOtp(email, type);
            if (result.success) {
                this.openOtpConfirm(this.email);
            }
        },

        openOtpConfirm: function (email) {
            let query = {
                'email': email,
            };
            this.$router.replace({ path: '/otp/confirm', query: query})
        },

    requestOtp: function (e) {
        e.preventDefault();
        this.$router.push({'path': '/otp/request'});
        return false;
    },

    checkHash: function () {
        const hash = window.location.hash;
        if (hash === '#otp-confirm') {
            this.confirmEmailWithOtp();
        }
    },

    nextPage: function () {
        if (!this.page.path) {
          this.$router.replace(this.pageDefault);
        } else {
          this.$router.replace(this.page);
        }
    },

    checkRouteQuery: function () {
        if (!( this.$route.query)) return;

        if (this.$route.query.ref) {
            let url = decodeURIComponent(this.$route.query.ref);
            let t = 0;
            url = url.replace(/\?/g, match => ++t === 2 ? '&' : match)
            let parsedUrl = new URL(url);
            let q = parsedUrl.searchParams.get("q");
            let email = parsedUrl.searchParams.get("email");
            let page = parsedUrl.searchParams.get("page");
            if (email) {
                this.email = email;
            }

            if (page in mapRoutes) {
                const path = mapRoutes[page];
                this.page.path = path;
            }

            if (q === "invito") {
                let query = {};
                for (const [key, value] of parsedUrl.searchParams) {
                    query[key] = value;
                }
                this.$router.replace({'path':'register', query: query});
            }
            let message = parsedUrl.searchParams.get("message");
            if (message) {
                this.message = message;
            }
        } else if (this.$route.query.path) {
            let path = decodeURIComponent(this.$route.query.path);
            if (path in mapRoutes) {
                let newPath = mapRoutes[path];
                this.page.path = newPath;
            }
            if (this.$route.query.query) {
                let query = this.$route.query.query;
                this.page.query = query;
            }
        }
    },
  update () {
    window.location.reload(true)
  },
  abortInstall () {
    this.prompt = false;
  },
  install () {
    this.dialog=true;

    prompt.install()
    .then(
      (result)=>{
        if(result.success) {
          this.prompt=false;
        }
      },
      (error)=>{
        console.warn(error);
      }
    )
    ;

  },
  submit: async function () {
      if (this.$refs.form.validate()) {
          // Native form submission is not yet supported
          const auth = {
              username: this.email,
              password: this.password,
          }
          await this.$store.dispatch("utente/login", auth);
          if (this.auth_token) {
              this.error = false;
              this.nextPage();
          } else {
              this.error = "Accesso negato";
          }
      }
},
    clear: function () {
      this.error = false;
      this.$refs.form.reset()
    },
    
    register: function () {
          this.$router.replace("/register");
    },

}
}
</script>

<style lang="scss">
@keyframes MOVE-0 {
  from {
    background-position: 0% 0%;
  }
  to {
    background-position: 100% 100%;
  }
}
@keyframes MOVE-1 {
  from {
    background-position: 100% 100%;
  }
  to {
    background-position: 0% 0%;
  }
}

.login {
  height: 100%;
}
.install-icon {
  display: inline-block;
  width: 2em;
  vertical-align: bottom;
}
.icona-browser {
  display: inline-block;
  width: 3em;
  vertical-align: bottom;
}
.login-container {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  background: var(--col-msp)  url("~@public/img/bg-home.jpg") no-repeat 50% 50%;
  background-size: cover;
  height: 100%;
  display: grid;
  align-items: center;

.v-label.error--text {
  color: rgba(0,0,0,0.6) !important;
}
  .update {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    .v-btn {
      background: #fffa;
    }
    .version {
      font-size: 10px;
      color: #fff;
      background: #0006;
      text-align: center;
      margin: 0;
      padding: 0;
    }
  }
  .install {
    margin: 10px auto;
    text-align: center;
    img {
      margin-bottom: 10px;
    }
  }
}
.v-dialog {
  position: relative;
  dt {
    border-top: 5px solid #eee;
    margin-top: 5px;
    padding-top: 5px;
    font-weight: bold;
    img {
      margin-right: 5px;
    }
    display: flex;
    align-items: center;
  }

}
</style>
