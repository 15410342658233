<template>
  <div class="login">
<div class="login-container" :class="classLogin" :style="styleLogin">
<v-form ref="form" v-model="valid" lazy-validation>


  <v-alert type="success" dismissible v-if="message">
  {{message}}
</v-alert>
<v-card
  class="mx-auto"
  max-width="344"
  outlined
  raised
  >
  <v-card-title primary-title>
    <div>
      <img class="logo" src="@public/img/logo_login.png" />
    </div>
  </v-card-title>
  <v-card-text>

        <legend>Verifica indirizzo email</legend>
    <p>Ti abbiamo inviato un codice OTP <span v-if='fromQuery'> a <strong>{{email}}</strong></span>.</p>
    <div >
<v-text-field
v-if = "!fromQuery"
  v-model="email"
  :rules="emailRules"
  label="E-mail"
  autocomplete
  required
  ></v-text-field>

    </div>
    <p><strong>Inseriscilo qui per attivare il tuo account.</strong></p>
<v-otp-input
  length="6"
  v-model="otp"
  @finish="otpConfirm"
></v-otp-input>

<p>Non hai ricevuto l'email?</p>
<p>Controlla che l'indirizzo email che hai inserito sia corretto oppure controlla nello spam.</p>
<p>Se non lo trovi, richiedi un nuovo codice e se non lo trovi ancora, <a href="mailto:info@coachpeaking.com">contattaci</a>.</p>
        <p class="annulla"><a class="btn btn-grigio" href="#login">annulla</a></p>
  </v-card-text>

  <v-card-actions>

  
</v-card-actions>

</v-card>
<v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
        OOPS...
        </v-card-title>

        <v-card-text>
            <span v-html="error"></span>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
          Chiudi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<v-alert type="error" v-if="error">
  {{error}}
</v-alert>
</v-form>
</div>

</div>

</template>

<script type="module">
import axios from 'axios'
import env from "@mixins/environment.js"
export default {
  mixins: [
    env,
  ],
  data: () => ({
      fromQuery: false,
      dialog: false,
      length: 6,
      classLogin: 'mv-1',
      styleLogin: 'background-position:'+Math.floor(Math.random()*100)+'% 100%',
      valid: true,
      email: '',
      otp: null,
    emailRules: [
      v => !!v || 'E-mail obbligatoria',
      v => /.+@.+/.test(v) || 'L\'e-mail deve essere valida'
    ],
      error: false,
      message: false,
      select: null,
      show1: false,
  }),
  mounted: function () {
      if (this.$route.query.email) {
          this.email = this.$route.query.email;
          this.fromQuery = true;
      }
  },

    methods: {
        
        cleanRegisterOtp: function () {
            this.otp = "";
        },

        otpConfirm: async function () {
            let data = {
                otp: this.otp,
                email: this.email,
            };

            const url = this.api_base_url+'/api/v2/utente.php?attiva';
            axios({
                method: 'POST',
                url: url,
                data: data
            })
            .then(
            (response) => {
                let result = response.data;
                if (!result.success) {
                    this.setMessage(result.message.text);
                    return;
                }
                this.setLogin(this.email);
                this.cleanRegisterOtp();
            },
            (error) => {
                this.setMessage(error.message.text);
            }
            )
        },

        setLogin: function (email) {
            let query = {
                'email': email,
            };
            this.$router.replace({ path: '/', query: query})
        },


        formatMessage: function (message) {
            if (!(message && message.length)) {
                return false;
            }
            let re = /__BR__/gi; 
            message = message.replace(re,"<br>");
            return  message;
        },
        
        clearMessage: function () {
            this.setMessage(false);
        },

        setMessage: function (message) {
            this.error = this.formatMessage(message);
            this.updateDialog();
        },

        updateDialog: function () {
            this.dialog = (this.error) ? true : false;
        },

    }
}
</script>

<style lang="scss"></style>
