<template>
  <div class="login">
<div class="login-container" :class="classLogin" :style="styleLogin">
<v-form ref="form" v-model="valid" lazy-validation>


  <v-alert type="success" dismissible v-if="message">
  {{message}}
</v-alert>
<v-card
  class="mx-auto"
  max-width="344"
  outlined
  raised
  >
  <v-card-title primary-title>
    <div>
      <img class="logo" src="@public/img/logo_login.png" />
    </div>
  </v-card-title>
  <v-card-text>

        <legend>Accesso con codice temporaneo.</legend>
    <p>Ti abbiamo inviato il codice a <strong>{{reset.email}}</strong>.</p>
    <p><strong>Inseriscilo qui per effettuare il login.</strong></p>

    <v-otp-input
  length="6"
  v-model="reset.otp"
  @finish="otpLogin" >
  </v-otp-input>

    <p><strong>La tua password attuale non verrà resettata.</strong></p>
    <p>Se desideri modificare la password lo potrai fare dal tuo profilo utente.</p>
  </v-card-text>

  <v-card-actions>

  
</v-card-actions>

</v-card>

<v-alert type="error" v-if="error">
  {{error}}
</v-alert>
</v-form>
</div>

</div>

</template>

<script type="module">
import env from "@mixins/environment.js"
import _api from "@mixins/api.js";
import _storage from "@mixins/storage.js"
export default {
  mixins: [
    env,
  ],
  data: () => ({
      reset: {
          email: "",
          otp: "",
        },
      length: 6,
      classLogin: 'mv-1',
    styleLogin: 'background-position:'+Math.floor(Math.random()*100)+'% 100%',
    valid: true,
    name: '',
    password: '',
    error: false,
    message: false,
    select: null,
    show1: false,


        
  }),
  mounted: function () {
    this.reset.email = this.$route.query.email;
},

    methods: {
        
        otpLogin: function () {
            let data = this.reset;
            const url = this.api_base_url+'/api/v2/utente.php?otp_login=1';
            _api(url,"POST", data)
            .then((result)=>{
                    if (result.status === 201) {
                        this.error = false;
                        _storage.setAuthToken(result.data);
                        this.$router.replace("/account/profilo");
                    } else {
                        this.error = false;
                    }
                })
            .catch(()=>{
                    this.error = false;
                }
            )},
    }
}
</script>

<style lang="scss"></style>
